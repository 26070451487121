export const statusCode = {
  paragraph: [
    { id: 0, name: 'lock' },
    { id: 1, name: 'hide' }
  ]
};

export const companyInfo = [
  {
    keyID: 1,
    nameVi: 'Tên tổ chức/Cá nhân',
    nameEn: 'Name of organization/ individual',
    code: 'name',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 2,
    nameVi: 'Địa chỉ',
    nameEn: 'Address',
    code: 'address',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 3,
    nameVi: 'Điện thoại',
    nameEn: 'Tel',
    code: 'phoneNumber',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 4,
    nameVi: 'Giấy CNĐKDN/ CMND',
    nameEn: 'Enterprise Registration Certificate/ ID Card ',
    code: 'brCertOrId',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 5,
    nameVi: 'Mã số thuế',
    nameEn: 'Tax Code',
    code: 'taxCode',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 6,
    nameVi: 'Số tài khoản',
    nameEn: 'Bank Account',
    code: 'bankAccount',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 12,
    nameVi: 'Swift Code',
    nameEn: 'Swift Code',
    code: 'swiftCode',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 13,
    nameVi: 'Địa chỉ ngân hàng',
    nameEn: 'Bank Address',
    code: 'bankAddress',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 7,
    nameVi: 'Tên ngân hàng',
    nameEn: 'Bank Name',
    code: 'nameOfBank',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 8,
    nameVi: 'Người thụ hưởng',
    nameEn: 'The Beneficiary',
    code: 'beneficiary',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 9,
    nameVi: 'Đại diện',
    nameEn: 'Representative',
    code: 'representative',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 10,
    nameVi: 'Chức vụ',
    nameEn: 'Position',
    code: 'position',
    content: '',
    contentPartner: ''
  },
  {
    keyID: 11,
    nameVi: 'Giấy uỷ quyền',
    nameEn: 'POA number/date',
    code: 'authorizationLetter',
    content: '',
    contentPartner: ''
  }
];

export const paperFormat = {
  orientation: {
    Portrait: {
      width: '210mm',
      minHeight: '297mm'
    },
    Landscape: {
      width: '297mm',
      minHeight: '210mm'
    }
  }
};

export const editorPageType = {
  CREATE_TEMPLATE: 1,
  UPDATE_TEMPLATE: 2,
  CREATE_DOCUMENT: 3,
  UPDATE_DOCUMENT: 4,
  CREATE_APPENDIX: 5,
  UPDATE_APPENDIX: 6,
  USE_TEMPLATE: 7,
  RENEW_PRINCIPLE: 8
};

export const requestReviewPermissions = {
  None: 0,
  Comment: 1,
  Editing: 2
};

export const jet = {
  None: 0,
  Jet: 1,
  NonJet: 2
};

export const leftToolBar = {
  None: 0,
  General: 1,
  Navigation: 2,
  ApprovalProcess: 3,
  DocumentList: 4
};

export const rightToolBar = {
  None: 0,
  RevisionParagraph: 1,
  Comment: 2
};

export const functionsEnum = {
  APPROVE_DRAFT_DOCUMENT: 1,
  ASSIGN: 2,
  REQUEST_FOR_BOD_APPROVE: 3,
  TRANSFER_TO_BUSINESS_PARTNER: 4,
  REQUEST_FOR_DEPARTMENT_REVIEW: 5,
  TRANSFER_TO_MARTECH: 6,
  APPROVE: 7,
  SIGN: 8,
  PRINT: 9,
  CLOSING: 10,
  // CLOSE: 11,
  REJECT: 12,
  CONFIRM_REVIEWED: 13,
  CONFIRM_SIGNED: 14,
  CONFIRM_RECEIVE_HARD_FILE: 15,
  REMIND_COMMENT: 16,
  CONFIRM_DOCUMENT_EFFECTIVE: 17,
  SUBMIT_TO_LEADER: 18,
  SEND_MAIL_LIQUID: 19,
  RESENT_TO_PARTNER: 20
};

export const menuResource = {
  RELATIVE_DOCUMENT: { key: '1', pathRoute: '/documents' },
  TEMPLATE: { key: '2', pathRoute: '/templates' },
  DEPARTMENT_RIGHTS: { key: '3', pathRoute: '/department-rights' },
  APPROVAL_PROCESS: { key: '4', pathRoute: '/approval-process' },
  SETTING: { key: '5', pathRoute: '/settings' },
  BOD_RIGHTS: { key: '6', pathRoute: '/bod-rights' },
  WAIT_HANDLE_DOCUMENT: { key: '7', pathRoute: '/waiting-documents' },
  MY_DOCUMENT: { key: '8', pathRoute: '/my-documents' },
  STORED_DOCUMENT: { key: '9', pathRoute: '/stored-documents' },
  REPORT_YEAR: { key: '10', pathRoute: '/reports-by-year' },
  REPORT_CATEGORY: { key: '11', pathRoute: '/reports-by-category' },
  REPORT_DEPARTMENT: { key: '12', pathRoute: '/reports-by-department' },
  REPORT_DEPT_CATEGORY: { key: '13', pathRoute: '' },
  DOCUMENTATION_GUIDE: { key: '14', pathRoute: '/documentation-guide' },
  HANDLED_DOCUMENT: { key: '15', pathRoute: '/handled-documents' },
  CATEGORY: { key: '16', pathRoute: '/category' },
  REPRESENTATIVE: { key: '17', pathRoute: '/representative' }
};

export const departmentRightTabs = {
  APPROVAL_RIGHTS: '1',
  TRACKING_RIGHTS: '2',
  ASSIGN_RIGHTS: '3'
};

export const notificationMessageTypes = {
  APPROVE: 'APPROVE',
  COMMENTDOC: 'COMMENTDOC',
  COMMENTAPPENDIX: 'COMMENTAPPENDIX',
  REJECT: 'REJECT',
  ASSIGN: 'ASSIGN',
  NORMAL: 'NORMAL'
};

export const departmentEn = {
  LEGAL: 9
};
