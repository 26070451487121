import { BellOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Alert, Button, Space, Typography } from 'antd';
import { useContext, useState } from 'react';
import { getNewestDocumentNotification } from 'src/api/notification.api';
import { notificationMessageTypes } from 'src/constant';
import { AppContext } from 'src/context/AppContext';
import { DocumentContext } from 'src/context/DocumentContext';
import { showNotification } from 'src/utils/notification';
import './AlertDocument.css';
import OpenLogByDocument from '../Button/OpenLogByDocument';

export default function AlertDocument() {
  const [alert, setAlert] = useState({});
  const { document } = useContext(DocumentContext);
  const { setAnchorChild } = useContext(AppContext);

  useQuery({
    queryKey: ['newestDocumentNotification', document?.id],
    queryFn: () => getNewestDocumentNotification(document?.id),
    // enabled: !!document?.id,
    onSuccess: (response) => {
      switch (response.data?.type) {
        case notificationMessageTypes.APPROVE:
          setAlert({
            ...response.data,
            typeColor: 'success',
            isShow: true
          });
          break;
        case notificationMessageTypes.COMMENTDOC:
          setAlert({
            ...response.data,
            typeColor: 'warning',
            isShow: true
          });
          break;
        case notificationMessageTypes.COMMENTAPPENDIX:
          setAlert({
            ...response.data,
            typeColor: 'warning',
            isShow: true
          });
          break;
        case notificationMessageTypes.REJECT:
          setAlert({
            ...response.data,
            typeColor: 'error',
            isShow: true
          });
          break;
        case notificationMessageTypes.ASSIGN:
          setAlert({
            ...response.data,
            typeColor: 'info',
            isShow: true
          });
          break;
        case notificationMessageTypes.NORMAL:
          setAlert({
            ...response.data,
            typeColor: 'info',
            isShow: true
          });
          break;
        default:
          return;
      }
    },
    onError: () => {
      setAlert({ isShow: false });
    }
  });

  const handleAnchorToDocChild = () => {
    setAnchorChild(alert.documentChildId);
  };

  const handleAnchorToAppendixChild = () => {
    const appendix = document?.appendixInfos?.find((x) => Number(x.id) === Number(alert?.documentId));
    if (appendix?.isOpeningDocument) {
      setAnchorChild(alert?.documentChildId);
    } else {
      showNotification(`Bạn hãy mở phụ lục ${appendix?.name ?? appendix?.title} trước rồi thử lại!`, 'warning');
    }
  };

  const handleRenderAlert = () => {
    switch (alert?.type) {
      case notificationMessageTypes.APPROVE:
        return <Typography.Text>{alert?.messageContent}</Typography.Text>;
      case notificationMessageTypes.COMMENTDOC:
        return (
          <Typography.Text>
            Văn bản {document?.title} vừa có comment mới{' '}
            <Button size='small' type='link' onClick={() => handleAnchorToDocChild()}>
              (nhấp để xem)
            </Button>
          </Typography.Text>
        );
      case notificationMessageTypes.COMMENTAPPENDIX:
        const appendix = document?.appendixInfos?.find((x) => x.id === alert.documentId);
        return (
          <Typography.Text>
            Phụ lục {appendix?.name ?? appendix?.title} vừa có comment mới{' '}
            <Button size='small' type='link' onClick={() => handleAnchorToAppendixChild()}>
              (nhấp để xem)
            </Button>
          </Typography.Text>
        );
      case notificationMessageTypes.REJECT:
        return <Typography.Text>Văn bản này vừa bị trả về. Vui lòng xem xét lại nội dung!</Typography.Text>;
      case notificationMessageTypes.ASSIGN:
        return <Typography.Text>Bạn được phân công thực hiện văn bản này!</Typography.Text>;
      case notificationMessageTypes.NORMAL:
        return <Typography.Text>Văn bản này đang cần bạn thực hiện!</Typography.Text>;
      default:
        return;
    }
  };

  return (
    alert?.isShow && (
      <Alert
        style={{ fontSize: '16px' }}
        className='document-alert'
        message={handleRenderAlert()}
        type={alert?.typeColor}
        banner
        icon={<BellOutlined />}
        closable
        onClose={() => setAlert({ ...alert, isShow: false })}
        action={
          <Space>
            <OpenLogByDocument aria-label='Log' type='link' text='Xem log' documentId={document?.id} />
          </Space>
        }
      />
    )
  );
}
